import React, { useState, useEffect } from 'react';
import Hero from './Hero/Hero';
import About from './About/About';
import Projects from './Projects/Projects';
import Contact from './Contact/Contact';
import Footer from './Footer/Footer';
import ThreeCanvas from '../ThreeCanvas';

import { PortfolioProvider } from '../context/context';

import { heroData, aboutData, projectsData, contactData, footerData } from '../mock/data';

function App() {
  const [hero, setHero] = useState({}); 
  const [about, setAbout] = useState({});
  const [projects, setProjects] = useState([]);
  const [contact, setContact] = useState({});
  const [footer, setFooter] = useState({});
  const container = React.useRef(null);

  useEffect(() => {
    setHero({ ...heroData });
    setAbout({ ...aboutData });
    setProjects([...projectsData]);
    setContact({ ...contactData });
    setFooter({ ...footerData });
  }, []);
  
  return (
    <div 
      onWheel={(e) => {
        container.current.onWheel();
      }}
      onTouchMove={(e) => {
        container.current.onTouchMove();
      }}
    >
      <div style={{position:"absolute", width: "100%", zIndex:'9999'}}>
        <PortfolioProvider value={{ hero, about, projects, contact, footer }}>
          <Hero />
          <About />
          <Projects />
          <Contact />
          <Footer />
        </PortfolioProvider>
      </div>
      <ThreeCanvas ref={container}/>
    </div>
  );
}

export default App;
