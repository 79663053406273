import React, { useContext, useState, useEffect } from 'react';
import Fade from 'react-reveal/Fade';
import { Container, Row, Col } from 'react-bootstrap';
import Title from '../Title/Title';
import AboutImg from '../Image/AboutImg';
import PortfolioContext from '../../context/context';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faMapMarkerAlt } from "@fortawesome/free-solid-svg-icons"

const About = () => {
  const { about } = useContext(PortfolioContext);
  const { img, paragraphOne, paragraphTwo, profile, paragraphThree, resume } = about;

  const [isDesktop, setIsDesktop] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    if (window.innerWidth > 769) {
      setIsDesktop(true);
      setIsMobile(false);
    } else {
      setIsMobile(true);
      setIsDesktop(false);
    }
  }, []);


  let paragraphOneBlock = '';
  if (paragraphOne !== undefined) {
    paragraphOneBlock = paragraphOne.map((paragraph,index) =>
      <p>
        {paragraph}
      </p>
    );
  }


  return (
    <section id="about">
      <Container>
        <Title title="About Me" />
        <Row className="about-wrapper">
          <Col md={6} sm={12}>
            <Fade bottom duration={1000} delay={600} distance="30px">
              <div className="about-wrapper__image">
                <AboutImg alt="profile picture" filename={img} />
              </div>
            </Fade>
          </Col>
          <Col md={6} sm={12}>
            <Fade left={isDesktop} bottom={isMobile} duration={1000} delay={1000} distance="30px">
              <div className="about-wrapper__info">
                <p className="about-wrapper__info-text">
                  {paragraphOneBlock ||
                    'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Excepturi neque, ipsa animi maiores repellendu distinctioaperiam earum dolor voluptatum consequatur blanditiis inventore debitis fuga numquam voluptate architecto itaque molestiae.'}
                </p>
                <Row>
                  <Col sm={2} xs={4} className="about-wrapper__info-text">
                    <p>名前</p>
                  </Col>
                  <Col sm={10} xs={8} className="about-wrapper__info-text">
                  <p>{profile ? profile.name : ''}</p>
                  </Col>
                  <Col sm={2} xs={4} className="about-wrapper__info-text">
                  <p>年齢</p>
                  </Col>
                  <Col sm={10} xs={8} className="about-wrapper__info-text">
                  <p>{profile ? profile.age : ''}</p>
                  </Col>
                  <Col sm={2} xs={4} className="about-wrapper__info-text">
                  <p>所在地</p>
                  </Col>
                  <Col sm={10} xs={8} className="about-wrapper__info-text">
                  <p class="d-inline-block">{profile ? profile.region : ''}</p>
                    <a 
                      href={profile ? profile.region_url : ''}
                      class="ml-1"
                      target="_blank"
                      rel="noopener"
                    >
                      <FontAwesomeIcon icon={faMapMarkerAlt} />
                    </a>
                  </Col>
                </Row>
                {resume && (
                  <span className="d-flex mt-3">
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      className="cta-btn cta-btn--resume"
                      href={resume}
                    >
                      Resume
                    </a>
                  </span>
                )}
              </div>
            </Fade>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default About;
