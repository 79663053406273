/** POC for three-fiber and cannon (a 3d physics lib)
 *
 *  useCannon is a custom hook that lets you link a physics body to a threejs
 *  mesh with zero effort. It will automatically update the mesh with the
 *  correct positioning.
 *
 *  When components with useCannon mount they are known to cannons world, when
 *  they unmount, they'll remove themselves from physics processing.
 *
 *  Check out three-fiber here: https://github.com/drcmda/react-three-fiber
 */

import * as CANNON from 'cannon'
import ReactDOM from 'react-dom'
import React, { useRef, Suspense, useMemo } from 'react'
import * as THREE from "three"
import { Canvas, useLoader, useFrame } from 'react-three-fiber'
import { useCannon, Provider } from './useCannon'
import './styles.css'
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader"
import CameraController from "./CameraController";
import create from "zustand";
import glbA from "./A3.glb";
import glbM from "./M3.glb";
import glbM2 from "./M3-2.glb";
import glbW from "./W3.glb";
import glbK from "./K3.glb";
import glbDOT from "./DOT.glb";
import glbO from "./O2.glb";
import glbO2 from "./O2-2.glb";
import glbR from "./R3.glb";
import glbS from "./S2.glb";
import glbS2 from "./S2-2.glb";
import glbT from "./T2.glb";
import glbT2 from "./T2-2.glb";
import glbU from "./U2.glb";
import glbU2 from "./U2-2.glb";

const useStore = create((set) => ({
  //画面がクリックされたらtrueに変更するstate
  tap: false,
  tapTrue: () => set((state) => ({ tap: true })),
  tapFalse: () => set((state) => ({ tap: false })),
  tapToggle: () => set((state) => {if (state.tap) { return {tap: false} } return {tap: true}}),
  isWheeling: () => set((state) => ({tap: true})),
  stopWheeling: () => set((state) => ({tap: false})),
}));

function Plane({ position }) {
  // Register plane as a physics body with zero mass
  const groundMat = new CANNON.Material('groundMat');
  const {ref, body} = useCannon({ mass: 0, material: groundMat }, body => {
    body.addShape(new CANNON.Plane())
    body.quaternion.setFromAxisAngle(new CANNON.Vec3(1, 0, 0), -Math.PI / 2);
    body.position.set(...position)
  })
  return (
    <mesh ref={ref} receiveShadow>
      <planeBufferGeometry attach="geometry" args={[1000, 1000]} />
      <meshPhongMaterial attach="material" color="#fff" />
    </mesh>
  )
}

function Wall({ position }) {
  // Register plane as a physics body with zero mass
  const groundMat = new CANNON.Material('groundMat');
  const {ref, body} = useCannon({ mass: 0, material: groundMat }, body => {
    body.addShape(new CANNON.Plane(1, 1))
    body.position.set(...position)
  })
  return (
    <mesh ref={ref} receiveShadow>
      <planeBufferGeometry attach="geometry" args={[1000, 1000]} />
      <meshPhongMaterial attach="material" color="#fff" />
    </mesh>
  )
}

function Box({ position, glb, size }) {
  const gltf = useLoader(GLTFLoader, glb);
  useMemo(() => gltf.scene.traverse(obj => {
    if (obj.type === "Mesh") {
      obj.material.dispose()
      obj.material = new THREE.MeshPhysicalMaterial({
        roughness: 0.8,
        color: obj.material.color,
      })
    }
  }), [gltf])


  // Register box as a physics body with mass
  const {ref, body} = useCannon({ mass: 1}, body => {
    body.addShape(new CANNON.Box(new CANNON.Vec3(...size)))
    body.velocity.set(0,0,0); 
    body.angularVelocity.set(0,0,0);
    body.position.set(...position)
  }, [])

  // tapのstateの値
  const tap = useStore((state) => state.tap);
  const positionHistry = useRef([]);
  const quaternionHistry = useRef([]);
  let count = useRef(0);

  useFrame((state) => {
    if (!tap) {
      body.mass = 0;
      body.updateMassProperties();

      if (0 < count.current) {
        body.position.x = positionHistry.current[count.current][0]
        body.position.y = positionHistry.current[count.current][1]
        body.position.z = positionHistry.current[count.current][2]
        body.quaternion.x = quaternionHistry.current[count.current][0]
        body.quaternion.y = quaternionHistry.current[count.current][1]
        body.quaternion.z = quaternionHistry.current[count.current][2]
        count.current--
        count.current--
      } else {
        body.position.x = position[0]
        body.position.y = position[1]
        body.position.z = position[2]
        body.quaternion.x = 0
        body.quaternion.y = 0
        body.quaternion.z = 0
        count.current = 0
        positionHistry.current = []
        quaternionHistry.current = []
      }
    } else {
      body.mass = 1;
      body.updateMassProperties();

      if (count.current <= 300) {
        count.current++
        positionHistry.current[count.current] = [body.position.x, body.position.y, body.position.z]
        quaternionHistry.current[count.current] = [body.quaternion.x, body.quaternion.y, body.quaternion.z]
      } else {
        body.position.x = positionHistry.current[300][0]
        body.position.y = positionHistry.current[300][1]
        body.position.z = positionHistry.current[300][2]
        body.quaternion.x = quaternionHistry.current[300][0]
        body.quaternion.y = quaternionHistry.current[300][1]
        body.quaternion.z = quaternionHistry.current[300][2]
      }
    }
  });

  const doubleSize = size.map((val) => {
    return val * 2; 
  });

  return (
      <group ref={ref}>
        <mesh castShadow receiveShadow>
          <boxGeometry attach="geometry" args={doubleSize} />
          <meshStandardMaterial
            attach="material"
            color={"orange"}
            transparent
            opacity={0}
          />
        </mesh>
        <primitive object={gltf.scene} position={[0, 0, 0]} dispose={null} />
      </group>
  )
}

function Ball({ position }) {
  const size = Math.random() / 2
  const fixPosition = [position[0] - Math.random() * 2, position[1], position[2] - Math.random() * 2]

  const {ref, body} = useCannon({ mass: 1}, body => {
    body.addShape(new CANNON.Sphere(size))
    body.angularVelocity.set(0, 0, 0);
    body.position.set(...fixPosition)
  }, [])

  // tapのstateの値
  const tap = useStore((state) => state.tap);
  const positionHistry = useRef([]);
  const quaternionHistry = useRef([]);
  let count = useRef(0);

  useFrame((state) => {
    if (!tap) {
      body.mass = 0;
      // body.updateMassProperties();

      if (0 < count.current) {
        body.position.x = positionHistry.current[count.current][0]
        body.position.y = positionHistry.current[count.current][1]
        body.position.z = positionHistry.current[count.current][2]
        body.quaternion.x = quaternionHistry.current[count.current][0]
        body.quaternion.y = quaternionHistry.current[count.current][1]
        body.quaternion.z = quaternionHistry.current[count.current][2]
        count.current--
        count.current--
      } else {
        body.position.x = fixPosition[0]
        body.position.y = fixPosition[1]
        body.position.z = fixPosition[2]
        body.quaternion.x = 0
        body.quaternion.y = 0
        body.quaternion.z = 0
        count.current = 0
        positionHistry.current = []
        quaternionHistry.current = []
      }
    } else {
      body.mass = 1;
      // body.updateMassProperties();

      if (count.current <= 300) {
        count.current++
        positionHistry.current[count.current] = [body.position.x, body.position.y, body.position.z]
        quaternionHistry.current[count.current] = [body.quaternion.x, body.quaternion.y, body.quaternion.z]
      } else {
        body.position.x = positionHistry.current[300][0]
        body.position.y = positionHistry.current[300][1]
        body.position.z = positionHistry.current[300][2]
        body.quaternion.x = quaternionHistry.current[300][0]
        body.quaternion.y = quaternionHistry.current[300][1]
        body.quaternion.z = quaternionHistry.current[300][2]
      }
    }
  });

  return (
    <group ref={ref}>
    <mesh receiveShadow>
      <sphereGeometry attach="geometry" args={[size, size, size]} />
      <meshStandardMaterial
        attach="material"
        color={"orange"}
        transparent
        opacity={0}
      />
    </mesh>
    </group>
  )
}

const ThreeCanvas = React.forwardRef((_, ref) => {
  const isWheeling = useStore((state) => state.isWheeling);
  const stopWheeling = useStore((state) => state.stopWheeling);
  let timeoutId;

  React.useImperativeHandle(ref, () => ({
    onWheel: () =>{
      isWheeling();

      // スクロールを停止して500ms後に終了とする
      clearTimeout( timeoutId ) ;
    
      timeoutId = setTimeout( function () {
        stopWheeling();
      }, 1000 ) ;
    },
    onTouchMove: () =>{
      isWheeling();

      // スクロールを停止して500ms後に終了とする
      clearTimeout( timeoutId ) ;
    
      timeoutId = setTimeout( function () {
        stopWheeling();
      }, 1000 ) ;
    }
  }));

  // When React removes (unmounts) the upper plane after 5 sec, objects should drop ...
  // This may seem like magic, but as the plane unmounts it removes itself from cannon and that's that
  // useEffect(() => void setTimeout(() => set(false), 5000), [])
  return (
    <Canvas 
      className="" 
      shadowMap 
      camera={{
        position: [20, 30, 40],
        near: 0.1,
        far: 1000
      }}
      // // tapのstateをtrueに変更
      // onClick={(e) => {
      //   tapTrue();
      // }}
      style={{position:'fixed', height:'100vh', width:'100vw'}} 
    >
      <CameraController />
      <ambientLight intensity={0.5} />
      <spotLight intensity={.3} position={[60, 60, 250]} angle={0.5} penumbra={1}  />
      <spotLight intensity={.3} position={[-60, 80, 250]} angle={0.5} penumbra={1}  />
      <Provider>
        <Plane position={[0, -10, 0]} />
        <Wall position={[0, 0, -20]} />
        <Suspense fallback="loading">
          <Box position={[-14, 21, 0]} glb={glbM} size={[3, 3, 3]} />
          <Box position={[-7, 21, 0]} glb={glbA} size={[3, 3, 3]}/>
          <Box position={[0, 21, 0]} glb={glbT} size={[3, 3, 3]}/>
          <Box position={[7, 21, 0]} glb={glbS} size={[3, 3, 3]}/>
          <Box position={[14, 21, 0]} glb={glbU} size={[3, 3, 3]}/>
          <Ball position={[-14, 17.5, 0]} />
          <Ball position={[-7, 17.5, 0]} />
          <Ball position={[0, 17.5, 0]} />
          <Ball position={[7, 17.5, 0]} />
          <Ball position={[14, 17.5, 0]} />
          <Box position={[-14, 14, 0]} glb={glbM2} size={[3, 3, 3]}/>
          <Box position={[-7, 14, 0]} glb={glbO} size={[3, 3, 3]}/>
          <Box position={[0, 14, 0]} glb={glbT2} size={[3, 3, 3]}/>
          <Box position={[7, 14, 0]} glb={glbS2} size={[3, 3, 3]}/>
          <Box position={[14, 14, 0]} glb={glbU2} size={[3, 3, 3]}/>
          <Ball position={[-14, 10.5, 0]} />
          <Ball position={[-7, 10.5, 0]} />
          <Ball position={[0, 10.5, 0]} />
          <Ball position={[7, 10.5, 0]} />
          <Ball position={[14, 10.5, 0]} />
          <Box position={[-14, 7, 0]} glb={glbDOT} size={[1, 1, 1]}/>
          <Box position={[-7, 7, 0]} glb={glbW} size={[3, 3, 3]}/>
          <Box position={[0, 7, 0]} glb={glbO2} size={[3, 3, 3]}/>
          <Box position={[7, 7, 0]} glb={glbR} size={[3, 3, 3]}/>
          <Box position={[14, 7, 0]} glb={glbK} size={[3, 3, 3]}/>
          <Ball position={[-14, 3.5, 0]} />
          <Ball position={[-7, 3.5, 0]} />
          <Ball position={[0, 3.5, 0]} />
          <Ball position={[7, 3.5, 0]} />
          <Ball position={[14, 3.5, 0]} />
        </Suspense>
      </Provider>
    </Canvas>
  )
})
export default ThreeCanvas;